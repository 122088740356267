import { useState } from 'react'
import Layout from '../layout/Layout'

import { Stepper, Step, Input, Checkbox, Radio, Select, Option } from "@material-tailwind/react";

import { LuUser } from "react-icons/lu";
import { CiMoneyBill } from "react-icons/ci";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { TbLoader3 } from 'react-icons/tb';
import axios from 'axios';

const TramitarCredito = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [isLastStep, setIsLastStep] = useState(false);
  const [isFirstStep, setIsFirstStep] = useState(false);
  const [loading, setLoading] = useState(false);

  //First Step
  const [primerNombre, setPrimerNombre] = useState('');
  const [segundoNombre, setSegundoNombre] = useState('');
  const [primerApellido, setPrimerApellido] = useState('');
  const [segundoApellido, setSegundoApellido] = useState('');
  const [rfc, setRfc] = useState('');
  const [curp, setCurp] = useState('');
  const [fechaNacimiento, setFechaNacimiento] = useState('');
  const [correoElectronico, setCorreoElectronico] = useState('');
  const [telefonoCelular, setTelefonoCelular] = useState('');
  const [avisoPrivacidad, setAvisoPrivacidad] = useState(false);
  const [emailError, setEmailError] = useState(true);
  const [phoneError, setPhoneError] = useState(true);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^\d{10}$/; // Assuming a 10-digit phone number
    return phoneRegex.test(phone);
  };

  const textLength = (text) => {
      return text.length >= 3;
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setCorreoElectronico(email);
    if (!validateEmail(email)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };

  const handlePhoneChange = (e) => {
    const phone = e.target.value;
    if (/^\d*$/.test(phone)) {
      setTelefonoCelular(phone);
      if (!validatePhone(phone)) {
        setPhoneError(true);
      } else {
        setPhoneError(false);
      }
    }
  };

  //Second Step
  const [codigoPostal, setCodigoPostal] = useState('');
  const [colonia, setColonia] = useState('');
  const [estado, setEstado] = useState('');
  const [ciudad, setCiudad] = useState('');
  const [calle, setCalle] = useState('');
  const [entreCalles, setEntreCalles] = useState('');
  const [numeroExterior, setNumeroExterior] = useState('');
  const [numeroInterior, setNumeroInterior] = useState('');
  const [autorizoHistorial, setAutorizoHistorial] = useState(false);
  
  //Third Step
  const [genero, setGenero] = useState('');
  const [estadoCivil, setEstadoCivil] = useState('');
  const [ocupacion, setOcupacion] = useState('');
  const [estadoLaboral, setEstadoLaboral] = useState('');
  const [municipioLaboral, setMunicipioLaboral] = useState('');
  const [calleLaboral, setCalleLaboral] = useState('');
  const [numeroLaboral, setNumeroLaboral] = useState('');
  const [puesto, setPuesto] = useState('');
  const [antiguedad, setAntiguedad] = useState('');
  const [area, setArea] = useState('');
  const [departamento, setDepartamento] = useState('');
  const [telefonoLaboral, setTelefonoLaboral] = useState('');
  const [ingresoMensual, setIngresoMensual] = useState('');
  const [nombreReferencia1, setNombreReferencia1] = useState('');
  const [generoReferencia1, setGeneroReferencia1] = useState('');
  const [celularReferencia1, setCelularReferencia1] = useState('');
  const [parentescoReferencia1, setParentescoReferencia1] = useState('');
  const [nombreReferencia2, setNombreReferencia2] = useState('');
  const [generoReferencia2, setGeneroReferencia2] = useState('');
  const [celularReferencia2, setCelularReferencia2] = useState('');
  const [parentescoReferencia2, setParentescoReferencia2] = useState('');

  const [fileDom, setFileDom] = useState(null);
  const [fileIng, setFileIng] = useState(null);
  const [fileINE, setFileINE] = useState(null);

  const handleFileChangeDomicilio = (event) => {
    const selectedFile = event.target.files[0];
    setFileDom(selectedFile);
  };
  
  const handleFileChangeINE = (event) => {
    const selectedFile = event.target.files[0];
    setFileINE(selectedFile);
  };
  
  const handleFileChangeIngresos = (event) => {
    const selectedFile = event.target.files[0];
    setFileIng(selectedFile);
  };

  const rfcRegex = /^([A-ZÑ&]{3,4})?(?:-)?(\d{2})(\d{2})(\d{2})(?:-)?([A-Z\d]{2})([A\d])$/;
  const curpRegex = /^[A-Z]{4}\d{6}[HM][A-Z]{5}[A-Z\d]{2}$/;

  const isValidRFC = (rfc) => rfcRegex.test(rfc);
  const isValidCURP = (curp) => curpRegex.test(curp);

  // Get today's date in YYYY-MM-DD format
  const today = new Date().toISOString().split('T')[0];

  

  const handleSubmit = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('curp', curp);
    formData.append('email', correoElectronico);
    formData.append('fechanacimiento', fechaNacimiento);
    formData.append('primerapellido', primerApellido);
    formData.append('primernombre', primerNombre);
    formData.append('rfc', rfc);
    formData.append('segundoapellido', segundoApellido);
    formData.append('segundonombre', segundoNombre);
    formData.append('telefono', telefonoCelular);
    formData.append('calle', calle);
    formData.append('codigopostal', codigoPostal);
    formData.append('colonia', colonia);
    formData.append('entrecalle', entreCalles);
    formData.append('estado', estado);
    formData.append('ciudad', ciudad);
    formData.append('numext', numeroExterior);
    formData.append('numint', numeroInterior);
    formData.append('genero', genero);
    formData.append('estadocivil', estadoCivil);
    formData.append('dedica', ocupacion);
    formData.append('empleoestado', estadoLaboral);
    formData.append('empleomunicipio', municipioLaboral);
    formData.append('empleocalle', calleLaboral);
    formData.append('empleonumero', numeroLaboral);
    formData.append('empleopuesto', puesto);
    formData.append('empleoantiguedad', antiguedad);
    formData.append('empleoarea', area);
    formData.append('empleodepartamento', departamento);
    formData.append('empleotelefono', telefonoLaboral);
    formData.append('empleoingreso', ingresoMensual);
    formData.append('refnombre1', nombreReferencia1);
    formData.append('refgenero1', generoReferencia1);
    formData.append('refcel1', celularReferencia1);
    formData.append('refparentesco1', parentescoReferencia1);
    formData.append('refnombre2', nombreReferencia2);
    formData.append('refgenero2', generoReferencia2);
    formData.append('refcel2', celularReferencia2);
    formData.append('refparentesco2', parentescoReferencia2);
    formData.append('compdomicilio', fileDom);
    formData.append('compingresos', fileIng);
    formData.append('compine', fileINE);

    axios.post('https://muebleria-villarreal.com/apimv/solicitudCreditoMail.php', formData, {
    // axios.post('/api2/solicitudCreditoMail.php', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
    .then(response => {
        console.log(response);
        setActiveStep(3);
        setLoading(false);
    })
    .catch(error => {
        console.log(error);
        setActiveStep(3);
        setLoading(false);
    });
};

  return (
    <Layout>
      {
          loading &&
              <div className="w-full h-full flex justify-center items-center fixed z-50 bg-white/95 top-0 left-0">
                  <TbLoader3 size={60} className='stroke-azul animate-spin' />
              </div>
      }
        <section className='w-full px-[5vw] pb-32 bg-azul pt-10 flex flex-col justify-start items-center relative z-0'>
            <h1 className='text-center text-white text-2xl lg:text-4xl font-medio '>¡Solicita tu crédito!</h1>
            <h2 className='pt-2 text-white text-sm lg:text-lg text-center'>Es muy fácil y rápido, solo ingresa tus datos.</h2>
        </section>
        <section className='w-full z-10 relative pb-20 px-4'>
            <div className='bg-white w-full max-w-4xl lg:min-h-[30vh] p-6 lg:py-10 lg:px-12 mx-auto -mt-20 flex flex-col justify-start items-start gap-4' style={{ boxShadow: "0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0)" }}>
              <div className="w-full px-6 lg:px-10 py-4">
                <Stepper
                className='w-full'
                  activeStep={activeStep}
                  isLastStep={(value) => setIsLastStep(value)}
                  isFirstStep={(value) => setIsFirstStep(value)}
                  activeLineClassName="bg-link"
                >
                  <Step
                    className='bg-gray-300'
                    activeClassName='bg-link'
                    completedClassName='bg-azul'
                  >
                    <LuUser size={20} stroke="white" />
                    <div className="absolute -bottom-[1.5rem] w-max text-center hidden lg:block">
                      <p className='text-xs text-azul'>Datos Personales</p>
                    </div>
                  </Step>
                  <Step 
                    className='bg-gray-300'
                    activeClassName='bg-link'
                    completedClassName='bg-azul'>
                    <CiMoneyBill size={20} fill={activeStep >= 1 ? 'white' : "black"} />
                    <div className="absolute -bottom-[1.5rem] w-max text-center hidden lg:block">
                      <p className={activeStep >= 1 ? 'text-xs text-azul' : 'text-xs text-gray-300'}>Información de crédito</p>
                    </div>
                  </Step>
                  <Step
                    className='bg-gray-300'
                    activeClassName='bg-link'
                    completedClassName='bg-azul'>
                    <IoIosInformationCircleOutline size={20} fill={activeStep >= 2 ? 'white' : "black"} />
                    <div className="absolute -bottom-[1.5rem] w-max text-center hidden lg:block">
                    <p className={activeStep >= 2 ? 'text-xs text-azul' : 'text-xs text-gray-300'}>Información adicional</p>
                    </div>
                  </Step>
                </Stepper>
              </div>
              <div className={`w-full flex-col justify-start items-start gap-4 transition-all ${activeStep == 0 ? "flex" : "hidden"}`}>
                <p className='font-medio mx-auto px-4 pt-8'>Escribe tu nombre como aparece en tu identificación oficial</p>
                <span className='text-xs font-medio'>* Campos requeridos</span>
                <div className="flex w-full flex-col lg:flex-row justify-between items-center gap-5">
                  <Input size='lg' label='Primer Nombre *' value={primerNombre} onChange={(e) => {
                    const value = e.target.value;
                    const filteredValue = value.replace(/[^a-zA-Z\s]/g, ''); // Allow only letters and spaces
                    if(filteredValue === ' ') {
                      setPrimerNombre("");
                    } else {
                      setPrimerNombre(filteredValue);
                    }
                  }} error={primerNombre.length < 3 && primerNombre !== ""} />
                  <Input size='lg' label='Segundo Nombre' value={segundoNombre} onChange={(e) => {
                    const value = e.target.value;
                    const filteredValue = value.replace(/[^a-zA-Z\s]/g, ''); // Allow only letters and spaces
                    if(filteredValue === ' ') {
                      setSegundoNombre("");
                    } else {
                      setSegundoNombre(filteredValue);
                    }
                  }} error={segundoNombre.length < 3 && segundoNombre !== ""} />
                </div>
                <div className="flex w-full flex-col lg:flex-row justify-between items-center gap-5">
                  <Input size='lg' label='Primer Apellido *' value={primerApellido} onChange={(e) => {
                    const value = e.target.value;
                    const filteredValue = value.replace(/[^a-zA-Z\s]/g, ''); // Allow only letters and spaces
                    if(filteredValue === ' ') {
                      setPrimerApellido("");
                    } else {
                      setPrimerApellido(filteredValue);
                    }
                  }} error={primerApellido.length < 3 && primerApellido !== ""} />
                  <Input size='lg' label='Segundo Apellido *' value={segundoApellido} onChange={(e) => {
                    const value = e.target.value;
                    const filteredValue = value.replace(/[^a-zA-Z\s]/g, ''); // Allow only letters and spaces
                    if(filteredValue === ' ') {
                      setSegundoApellido("");
                    } else {
                      setSegundoApellido(filteredValue);
                    }
                  }} error={segundoApellido.length < 3 && segundoApellido !== ""} />
                </div>
                <div className="flex w-full flex-col lg:flex-row justify-between items-center gap-5">
                  <Input size='lg' label='RFC *' className='' value={rfc} onChange={(e) => setRfc(e.target.value.toUpperCase())} error={!isValidRFC(rfc) && rfc !== ""} />
                  <Input size='lg' label='CURP (No obligatorio)' className='' value={curp} onChange={(e) => setCurp(e.target.value.toUpperCase())} error={!isValidCURP(curp) && curp !== ""} />
                </div>
                <div className="flex w-full flex-col lg:flex-row justify-between items-center gap-5">
                  <Input size='lg' label='Fecha de Nacimiento *' max={today} type='date' value={fechaNacimiento} onChange={(e) => setFechaNacimiento(e.target.value)} />
                </div>
                <span className='text-xs -mb-4'>Selecciona tu genero*</span>
                <div className="flex w-full flex-col lg:flex-row justify-between items-center gap-5">
                  <div className="flex gap-3 w-full">
                    <Radio onChange={(e) => setGenero(e.target.value)} name="genero" value="Hombre" label={
                      <span className='text-xs'>Hombre</span>
                    } />
                    <Radio onChange={(e) => setGenero(e.target.value)} name="genero" value="Mujer" label={
                      <span className='text-xs'>Mujer</span>
                    } />
                  </div>
                  <Select onChange={(e) => setEstadoCivil(e)} size='lg' label='Estado Civil *'>
                    <Option value='soltero'>Solter@</Option>
                    <Option value='casado'>Casad@</Option>
                    <Option value='divorciado'>Divorciad@</Option>
                    <Option value='viudo'>Viud@</Option>
                  </Select>
                </div>
                <p className='font-medio mx-auto px-4 pt-8'>Datos de contacto</p>
                <span className='text-xs font-medio'>* Campos requeridos</span>
                <div className="flex w-full flex-col lg:flex-row justify-between items-center gap-5">
                  <Input size='lg' label='Correo electrónico *' value={correoElectronico} onChange={handleEmailChange} type='email' error={correoElectronico.length < 3 && correoElectronico !== ""} />
                  <Input size='lg' label='Teléfono celular *' value={telefonoCelular} onChange={handlePhoneChange} type="tel" error={phoneError && telefonoCelular !== ""} />
                </div>
                <br />
                <div className='mx-auto checkboxAzul'>
                  <Checkbox onChange={(e) => setAvisoPrivacidad(e.target.checked)} checked={avisoPrivacidad} color='blue' label={
                    <span className='font-medio'>Acepto de conformidad el contenido del <a href="/Empresa/AvisodePrivacidad" target='_blank' className='font-medio text-azul underline'>Aviso de Privacidad</a></span>
                  } />
                </div>
                <button
                disabled={
                  genero === '' ||
                  !estadoCivil ||
                  !textLength(primerNombre) ||  
                  !textLength(primerApellido) || 
                  !textLength(segundoApellido) || 
                  !textLength(fechaNacimiento) || 
                  emailError || 
                  phoneError ||
                  !isValidRFC(rfc) ||
                  !avisoPrivacidad
                }
                className={`bg-link py-2 px-4 text-white rounded-md mx-auto w-full lg:w-fit ${genero === '' || !estadoCivil ||!textLength(primerNombre) || !textLength(primerApellido) || !textLength(segundoApellido) || !textLength(fechaNacimiento) || emailError || phoneError || !isValidRFC(rfc) || !avisoPrivacidad  ? "opacity-50" : ""}`} onClick={() => setActiveStep(1)}>Continuar</button>
              </div>
              <div className={`w-full flex-col justify-start items-start gap-4 transition-all ${activeStep == 1 ? "flex" : "hidden"}`}>
                <p className='font-medio mx-auto px-4 pt-8'>Domicilio</p>
                <span className='text-xs font-medio'>* Campos requeridos</span>
                <div className="flex w-full flex-col lg:flex-row justify-between items-center gap-5">
                  <Input max={5} size='lg' label='Código Postal *' value={codigoPostal} onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) {
                      setCodigoPostal(value);
                    }
                  }} error={codigoPostal.length < 5 && codigoPostal !== "" || codigoPostal.length > 5} />
                  <Input size='lg' label='Colonia *' value={colonia} onChange={(e) => {
                    const value = e.target.value;
                    const filteredValue = value.replace(/[^a-zA-Z\s]/g, ''); // Allow only letters and spaces
                    if(filteredValue === ' ') {
                      setColonia("");
                    } else {
                      setColonia(filteredValue);
                    }
                  }} error={colonia.length < 3 && colonia !== ""} />
                </div>
                <div className="flex w-full flex-col lg:flex-row justify-between items-center gap-5">
                  <Input size='lg' label='Estado *' value={estado} onChange={(e) => {
                    const value = e.target.value;
                    const filteredValue = value.replace(/[^a-zA-Z\s]/g, ''); // Allow only letters and spaces
                    if(filteredValue === ' ') {
                      setEstado("");
                    } else {
                      setEstado(filteredValue);
                    }
                  }} error={estado.length < 3 && estado !== ""} />
                  <Input size='lg' label='Ciudad *' value={ciudad} onChange={(e) => {
                    const value = e.target.value;
                    const filteredValue = value.replace(/[^a-zA-Z\s]/g, ''); // Allow only letters and spaces
                    if(filteredValue === ' ') {
                      setCiudad("");
                    } else {
                      setCiudad(filteredValue);
                    }
                  }} error={ciudad.length < 3 && ciudad !== ""} />
                </div>
                <div className="flex w-full flex-col lg:flex-row justify-between items-center gap-5">
                  <Input size='lg' label='Calle *' value={calle} onChange={(e) => {
                    const value = e.target.value;
                    const filteredValue = value.replace(/[^a-zA-Z0-9\s]/g, '');
                    if(filteredValue === ' ') {
                      setCalle("");
                    } else {
                      setCalle(filteredValue);
                    }
                  }} error={calle.length < 3 && calle !== ""} />
                  <Input size='lg' label='Entre Calles *' value={entreCalles} onChange={(e) => {
                    const value = e.target.value;
                    const filteredValue = value.replace(/[^a-zA-Z0-9\s]/g, '');
                    if(filteredValue === ' ') {
                      setEntreCalles("");
                    } else {
                      setEntreCalles(filteredValue);
                    }
                  }} error={entreCalles.length < 3 && entreCalles !== ""} />
                </div>
                <div className="flex w-full flex-col lg:flex-row justify-between items-center gap-5">
                  <Input size='lg' label='Numero Exterior *' value={numeroExterior} onChange={(e) => {
                    const value = e.target.value;
                    const filteredValue = value.replace(/[^a-zA-Z0-9\s]/g, ''); // Allow only letters, numbers, and spaces
                    if (filteredValue === ' ') {
                      setNumeroExterior("");
                    } else {
                      setNumeroExterior(filteredValue);
                    }
                  }} error={numeroExterior.length < 1 && numeroExterior !== ""} />
                  <Input size='lg' label='Numero Interior' value={numeroInterior} onChange={(e) => {
                    const value = e.target.value;
                    const filteredValue = value.replace(/[^a-zA-Z0-9\s]/g, ''); // Allow only letters, numbers, and spaces
                    if (filteredValue === ' ') {
                      setNumeroInterior("");
                    } else {
                      setNumeroInterior(filteredValue);
                    }
                  }} />
                </div>
                <br />
                <div className='mx-auto checkboxAzul'>
                  <Checkbox onChange={(e) => setAutorizoHistorial(e.target.checked)} checked={autorizoHistorial} color='blue' label={
                    <span className='font-medio'>Autorizo consultar mi historial de crédito</span>
                  } />
                </div>
                <div className="flex flex-col lg:flex-row justify-center items-center gap-2 w-full">
                  <button className='bg-dark py-2 px-4 text-white rounded-md w-full lg:w-fit' onClick={() => setActiveStep(0)}>Regresar</button>
                  <button
                  disabled={
                    codigoPostal.length !== 5 ||
                    !textLength(colonia) ||
                    !textLength(estado) ||
                    !textLength(ciudad) ||
                    !textLength(calle) ||
                    !textLength(entreCalles) ||
                    !numeroExterior ||
                    !autorizoHistorial
                  }
                  className={`bg-link py-2 px-4 text-white rounded-md w-full lg:w-fit ${codigoPostal.length !== 5 ||!textLength(colonia) ||!textLength(estado) ||!textLength(ciudad) ||!textLength(calle) ||!textLength(entreCalles) ||!numeroExterior ||!autorizoHistorial ? "opacity-50" : ""}`} onClick={() => setActiveStep(2)}>Continuar</button>
                </div>
              </div>
              <div className={`w-full flex-col justify-start items-start gap-4 transition-all ${activeStep == 2 ? "flex" : "hidden"}`}>
                <p className='font-medio mx-auto px-4 pt-8'>Esta Información nos ayuda a hacer más rápida tu solicitud.</p>
                <span className='text-xs font-medio'>* Campos requeridos</span>
                <p className='font-negro mx-auto px-4 pt-2'>INFORMACION LABORAL</p>
                <div className="flex w-full flex-col lg:flex-row justify-between items-center gap-5">
                  <Input size='lg' value={ocupacion} onChange={(e) => {
                    const value = e.target.value;
                    const filteredValue = value.replace(/[^a-zA-Z\s]/g, ''); // Allow only letters and spaces
                    if(filteredValue === ' ') {
                      setOcupacion("");
                    } else {
                      setOcupacion(filteredValue);
                    }
                  }} label='¿A qué te dedicas? *' error={ocupacion.length < 3 && ocupacion !== ""} />
                </div>
                <div className="flex w-full flex-col lg:flex-row justify-between items-center gap-5">
                  <Input size='lg' value={estadoLaboral} onChange={(e) => {
                    const value = e.target.value;
                    const filteredValue = value.replace(/[^a-zA-Z\s]/g, ''); // Allow only letters and spaces
                    if(filteredValue === ' ') {
                      setEstadoLaboral("");
                    } else {
                      setEstadoLaboral(filteredValue);
                    }
                  }} label='Estado *' error={estadoLaboral.length < 3 && estadoLaboral !== ""} />
                  <Input size='lg' value={municipioLaboral} onChange={(e) => {
                    const value = e.target.value;
                    const filteredValue = value.replace(/[^a-zA-Z\s]/g, ''); // Allow only letters and spaces
                    if(filteredValue === ' ') {
                      setMunicipioLaboral("");
                    } else {
                      setMunicipioLaboral(filteredValue);
                    }
                  }} label='Municipio *' error={municipioLaboral.length < 3 && municipioLaboral !== ""} />
                </div>
                <div className="flex w-full flex-col lg:flex-row justify-between items-center gap-5">
                  <Input size='lg' value={calleLaboral} onChange={(e) => {
                    const value = e.target.value;
                    const filteredValue = value.replace(/[^a-zA-Z0-9\s]/g, '');
                    if(filteredValue === ' ') {
                      setCalleLaboral("");
                    } else {
                      setCalleLaboral(filteredValue);
                    }
                  }} label='Calle *' error={calleLaboral.length < 3 && calleLaboral !== ""} />
                  <Input size='lg' value={numeroLaboral} onChange={(e) => {
                    const value = e.target.value;
                    const filteredValue = value.replace(/[^a-zA-Z0-9\s]/g, ''); // Allow only letters, numbers, and spaces
                    if (filteredValue === ' ') {
                      setNumeroLaboral("");
                    } else {
                      setNumeroLaboral(filteredValue);
                    }
                  }} label='Numero' />
                </div>
                <div className="flex w-full flex-col lg:flex-row justify-between items-center gap-5">
                  <Input size='lg' value={puesto} onChange={(e) => {
                    const value = e.target.value;
                    const filteredValue = value.replace(/[^a-zA-Z\s]/g, ''); // Allow only letters and spaces
                    if(filteredValue === ' ') {
                      setPuesto("");
                    } else {
                      setPuesto(filteredValue);
                    }
                  }} label='Puesto *' error={puesto.length < 3 && puesto !== ""} />
                  <Input size='lg' value={antiguedad} onChange={(e) => {
                    const value = e.target.value;
                    const filteredValue = value.replace(/[^a-zA-Z0-9\s]/g, ''); // Allow only letters, numbers, and spaces
                    if (filteredValue === ' ') {
                      setAntiguedad("");
                    } else {
                      setAntiguedad(filteredValue);
                    }
                  }} label='Antiguedad' />
                </div>
                <div className="flex w-full flex-col lg:flex-row justify-between items-center gap-5">
                  <Input size='lg' value={area} onChange={(e) => {
                    const value = e.target.value;
                    const filteredValue = value.replace(/[^a-zA-Z\s]/g, ''); // Allow only letters and spaces
                    if(filteredValue === ' ') {
                      setArea("");
                    } else {
                      setArea(filteredValue);
                    }
                  }} label='Area' />
                  <Input size='lg' value={departamento} onChange={(e) => {
                    const value = e.target.value;
                    const filteredValue = value.replace(/[^a-zA-Z\s]/g, ''); // Allow only letters and spaces
                    if(filteredValue === ' ') {
                      setDepartamento("");
                    } else {
                      setDepartamento(filteredValue);
                    }
                  }} label='Departamento' />
                </div>
                <div className="flex w-full flex-col lg:flex-row justify-between items-center gap-5">
                  <Input size='lg' value={telefonoLaboral} onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) {
                      setTelefonoLaboral(value);
                    }
                  }} label='Teléfono' type='tel' />
                  <Input size='lg' value={ingresoMensual} onChange={(e) => {
                    const value = e.target.value;
                    const filteredValue = value.replace(/[^0-9]/g, ''); // Allow only numbers
                    if (filteredValue === ' ') {
                      setIngresoMensual("");
                    } else {
                      const formattedValue = new Intl.NumberFormat('en-US').format(filteredValue);
                      setIngresoMensual(formattedValue);
                    }
                  }} label='Ingreso mensual*' error={ingresoMensual.length < 3 && ingresoMensual !== ""} />
                </div>
                <p className='font-medio mx-auto px-4 pt-8'>Referencia Personal 1</p>
                <div className="flex w-full flex-col lg:flex-row justify-between items-center gap-5">
                  <Input size='lg' value={nombreReferencia1} onChange={(e) => {
                    const value = e.target.value;
                    const filteredValue = value.replace(/[^a-zA-Z\s]/g, ''); // Allow only letters and spaces
                    if(filteredValue === ' ') {
                      setNombreReferencia1("");
                    } else {
                      setNombreReferencia1(filteredValue);
                    }
                  }} label='Nombre completo *' error={nombreReferencia1.length < 3 && nombreReferencia1 !== ""} />
                </div>
                <span className='text-xs -mb-4'>Genero*</span>
                <div className="flex gap-3 w-full">
                  <Radio onChange={(e) => setGeneroReferencia1(e.target.value)} name="genero1" label={
                    <span className='text-xs'>Hombre</span>
                  } />
                  <Radio onChange={(e) => setGeneroReferencia1(e.target.value)} name="genero1" label={
                    <span className='text-xs'>Mujer</span>
                  } />
                </div>
                <div className="flex w-full flex-col lg:flex-row justify-between items-center gap-5">
                  <Input size='lg' value={celularReferencia1} onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) {
                      setCelularReferencia1(value);
                    }
                  }} label='Celular *'type='tel' error={celularReferencia1.length < 3 && celularReferencia1 !== ""} />
                  <Input size='lg' value={parentescoReferencia1} onChange={(e) => {
                    const value = e.target.value;
                    const filteredValue = value.replace(/[^a-zA-Z\s]/g, ''); // Allow only letters and spaces
                    if(filteredValue === ' ') {
                      setParentescoReferencia1("");
                    } else {
                      setParentescoReferencia1(filteredValue);
                    }
                  }} label='Parentesco *' error={parentescoReferencia1.length < 3 && parentescoReferencia1 !== ""} />
                </div>
                <p className='font-medio mx-auto px-4 pt-8'>Referencia Personal 2</p>
                <div className="flex w-full flex-col lg:flex-row justify-between items-center gap-5">
                  <Input size='lg' value={nombreReferencia2} onChange={(e) => {
                    const value = e.target.value;
                    const filteredValue = value.replace(/[^a-zA-Z\s]/g, ''); // Allow only letters and spaces
                    if(filteredValue === ' ') {
                      setNombreReferencia2("");
                    } else {
                      setNombreReferencia2(filteredValue);
                    }
                  }} label='Nombre completo *' error={nombreReferencia2.length < 3 && nombreReferencia2 !== ""} />
                </div>
                <span className='text-xs -mb-4'>Genero*</span>
                <div className="flex gap-3 w-full">
                  <Radio onChange={(e) => setGeneroReferencia2(e.target.value)} name="genero2" label={
                    <span className='text-xs'>Hombre</span>
                  } />
                  <Radio onChange={(e) => setGeneroReferencia2(e.target.value)} name="genero2" label={
                    <span className='text-xs'>Mujer</span>
                  } />
                </div>
                <div className="flex w-full flex-col lg:flex-row justify-between items-center gap-5">
                  <Input size='lg' value={celularReferencia2} onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) {
                      setCelularReferencia2(value);
                    }
                  }} label='Celular *'type='tel' error={celularReferencia2.length < 3 && celularReferencia2 !== ""} />
                  <Input size='lg' value={parentescoReferencia2} onChange={(e) => {
                    const value = e.target.value;
                    const filteredValue = value.replace(/[^a-zA-Z\s]/g, ''); // Allow only letters and spaces
                    if(filteredValue === ' ') {
                      setParentescoReferencia2("");
                    } else {
                      setParentescoReferencia2(filteredValue);
                    }
                  }} label='Parentesco *' error={parentescoReferencia2.length < 3 && parentescoReferencia2 !== ""} />
                </div>
                <p className='font-medio mx-auto px-4 pt-8'>SUBE TU PAPELERIA</p>
                <div className="flex w-full flex-col lg:flex-row justify-between items-center gap-5">
                  <Input size='lg' label='INE *' type='file' accept="image/*,.pdf,.doc,.docx,;capture=camera" onChange={handleFileChangeINE} />
                </div>
                <div className="flex w-full flex-col lg:flex-row justify-between items-center gap-5">
                  <Input size='lg' label='Comprobante de Domicilio *' type='file' accept="image/*,.pdf,.doc,.docx,;capture=camera" onChange={handleFileChangeDomicilio} />
                  <Input size='lg' label='Comprobante de Ingresos *' type='file' accept="image/*,.pdf,.doc,.docx,;capture=camera" onChange={handleFileChangeIngresos} />
                </div>
                <br />
                <div className="flex flex-col lg:flex-row justify-center items-center gap-2 w-full">
                  <button className='bg-dark py-2 px-4 text-white rounded-md w-full lg:w-fit' onClick={() => setActiveStep(1)}>Regresar</button>
                  <button 
                  disabled={
                    !textLength(ocupacion) ||
                    !textLength(estadoLaboral) ||
                    !textLength(municipioLaboral) ||
                    !textLength(calleLaboral) ||
                    !textLength(puesto) ||
                    !textLength(ingresoMensual) ||
                    !textLength(nombreReferencia1) ||
                    generoReferencia1 === '' ||
                    !textLength(celularReferencia1) ||
                    !textLength(parentescoReferencia1) ||
                    !textLength(nombreReferencia2) ||
                    generoReferencia2 === '' ||
                    !textLength(celularReferencia2) ||
                    !textLength(parentescoReferencia2) ||
                    !fileDom ||
                    !fileIng ||
                    !fileINE
                  }
                  className={
                    `bg-link py-2 px-4 text-white rounded-md w-full lg:w-fit ${
                    !textLength(ocupacion) ||
                    !textLength(estadoLaboral) ||
                    !textLength(municipioLaboral) ||
                    !textLength(calleLaboral) ||
                    !textLength(puesto) ||
                    !textLength(ingresoMensual) ||
                    !textLength(nombreReferencia1) ||
                    generoReferencia1 === '' ||
                    !textLength(celularReferencia1) ||
                    !textLength(parentescoReferencia1) ||
                    !textLength(nombreReferencia2) ||
                    generoReferencia2 === '' ||
                    !textLength(celularReferencia2) ||
                    !textLength(parentescoReferencia2) ||
                    !fileDom ||
                    !fileIng ||
                    !fileINE
                    ? "opacity-50" : ""}`
                  } onClick={handleSubmit}>Enviar Formulario</button>
                </div>
              </div>
              <div className={`w-full flex-col justify-center items-center gap-2 transition-all pt-5 ${activeStep == 3 ? "flex" : "hidden"}`}>
                <h2 className='text-lg'>¡Muchas gracias, <b className='text-azul font-medio uppercase'>{primerNombre}</b>!</h2>
                <p className='text-sm'>Analizaremos tu solicitud en breve.</p>
                <img src="images/enviarsolicitud.png" className="w-8/12 lg:w-52 pt-10 object-cover"></img>
                <br />
                <button className='bg-link py-2 px-4 text-white rounded-md mx-auto w-full lg:w-fit' onClick={() => window.location.href = "/"}>Regresar a la Pagina Principal</button>
              </div>
            </div>
        </section>
    </Layout>
  )
}

export default TramitarCredito