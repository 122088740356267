import { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import { MdWatchLater } from "react-icons/md";
import { TbLoader3 } from "react-icons/tb";

import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";

import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Rating,
} from "@material-tailwind/react";
import { useParams } from "react-router-dom";

import { Toaster, toast } from "sonner";
import { CardReview } from "../components/CardReview";

const CONTENTS = [
  {
    name: "Carlos Méndez",
    feedback:
      "Excelente calidad en cada uno de sus productos. Compré varios artículos para mi hogar, y todos han sido muy duraderos y de diseño impecable. La atención al cliente también es excelente; el personal siempre está dispuesto a ayudar y resolver cualquier duda. Sin duda, recomendaría Mueblería Villarreal a quien esté buscando calidad y buen servicio.",
    date: "Agosto 24 2024",
    rating: 5,
  },
  {
    name: "Ana Lucía Gómez",
    feedback:
      "Los productos de Mueblería Villarreal tienen una gran relación calidad-precio. Me sorprendió la variedad de opciones y estilos disponibles, hay algo para cada gusto y necesidad. Aunque hubo un pequeño retraso en la entrega, el equipo fue muy atento y me mantuvo informada en todo momento. Definitivamente, volvería a comprar aquí.",
    date: "Febrero 12 2024",
    rating: 4,
  },
  {
    name: "José Luis Herrera",
    feedback:
      "La experiencia de compra en Mueblería Villarreal fue inmejorable. Los muebles que compré tienen un acabado y diseño increíble; se nota el cuidado en cada detalle. Además, la tienda ofrece facilidades de pago que hicieron mucho más sencillo adquirir todo lo que necesitaba. ¡Muy recomendada!",
    date: "Febrero 10 2024",
    rating: 5,
  },
];

const dataTable = [
  {
    label: "Información Adicional",
    value: "react",
    desc: [
      "Información adicional",
      "- Fotografías ilustrativas.",
      "- Pueden existir variación en tonos y acabados contra su versión en físico.",
      "- No incluye accesorios ni complementos decorativos.",
      "- Precios sujeto a cambio sin previo aviso.",
      "- Valido hasta agotar la existencia.",
    ],
  },
  {
    label: "Cuidados y limpieza",
    value: "vue",
    desc: [
      "Cuidados y limpieza",
      "- Proteger del polvo y ambientes exteriores.",
      "- Antes de aplicar productos de limpieza pruebe sobre superficies similares.",
      "- Tenga precaución al mover o montar cristales, para evitar estrellar o rayar.",
      "- Mantener piezas metálicas lejos de humedad para evitar la corrosión.",
    ],
  },
  {
    label: "Reviews",
    value: "angular",
    desc: [],
  },
];

const Producto = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState(false);

  const handleImageClick = (img) => {
    setImageUrl(img);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const { id } = useParams();
  const { grupo } = useParams();
  const [data, setData] = useState({
    articulo: [],
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(
      "https://muebleria-villarreal.com/apimv/articuloController.php?grupo=" +
        grupo +
        "&articulo=" +
        id
    )
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        console.log(data)
        // console.log(data.articulo[0].exist)
        setTimeout(() => {
          setLoading(false);
        }, 500);
      });
  }, [grupo, id]);

  const [zoom, setZoom] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const [qty, setQty] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [openModalCredito, setOpenModalCredito] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(!openModal);
  };
  const handleOpenModalCredito = () => {
    setOpenModalCredito(!openModalCredito);
  };

  const handleMouseEnter = () => {
    setZoom(true);
  };

  const handleMouseLeave = () => {
    setZoom(false);
    setTimeout(() => {
      setPosition({ x: 0, y: 0 });
    }, 300); // Match the duration of the CSS transition
  };

  const handleMouseMove = (e) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = ((e.pageX - left) / width) * 100;
    const y = ((e.pageY - top) / height) * 100;
    setPosition({ x, y });
  };

  const handleTouchMove = (event) => {
    // Convert touch event to mouse event
    const touch = event.touches[0];
    const mouseEvent = {
      pageX: touch.pageX,
      pageY: touch.pageY,
      target: event.target,
    };
    document.getElementsByTagName("body")[0].style.overflow = "hidden";
    handleMouseMove(mouseEvent);
    setZoom(true);
  };

  const handleTouchEnd = () => {
    setZoom(false);
    setTimeout(() => {
      setPosition({ x: 0, y: 0 });
    }, 300); // Match the duration of the CSS transition
    document.getElementsByTagName("body")[0].style.overflow = "auto";
  };

  const agregarCarrito = () => {
    let cart = JSON.parse(localStorage.getItem("cart"));
    let inventario = Math.floor(data.articulo[0].exist);

    // Ensure cart is an array
    if (!Array.isArray(cart)) {
      cart = [];
    }

    const productIndex = cart.findIndex(
      (item) => item.articulo === data.articulo[0].articulo
    );

    if (productIndex !== -1) {
      let cantidad = cart[productIndex].qty + qty;
      if (cantidad > inventario) {
        cantidad = inventario;
        // notifyInventario(inventario);
        toast.error(
          "Solamente contamos con " +
            inventario +
            " articulos en el inventario.",
          {
            duration: 3000,
          }
        );
      } else {
        // notify();
        toast.success("Articulo Agregado", {
          duration: 3000,
        });
      }
      cart[productIndex].qty = cantidad;
    } else {
      // notify();
      toast.success("Articulo Agregado");
      cart.push({
        articulo: data.articulo[0].articulo,
        qty: qty,
        bodega: data.bodegas[0].bodega,
      });
    }

    localStorage.setItem("cart", JSON.stringify(cart));
    window.dispatchEvent(new Event("cartUpdated"));
  };

  return (
    <Layout>
      <Toaster richColors closeButton position="top-right" />
      {loading && (
        <div className="w-full h-lvh flex justify-center items-center fixed z-50 bg-white/95 top-0 left-0">
          <TbLoader3 size={100} className="stroke-azul animate-spin" />
        </div>
      )}
      <Dialog size="md" open={openModal} handler={handleOpenModal}>
        <DialogHeader className="text-xl lg:text-2xl ">
          Disponible en la siguiente sucursal:
        </DialogHeader>
        <DialogBody className="overflow-y-auto max-h-[50vh]">
          {data.existencias &&
            data.existencias.length &&
            data.existencias.map((value, index) => {
              return (
                <div
                  key={index}
                  className="flex flex-row justify-between items-center gap-2 w-full border-b border-b-gris/30 py-2 px-2"
                >
                  <div className="flex flex-col justify-start items-start gap-1">
                    <p className="text-azul font-medio text-xl lg:text-2xl">
                      {value.sucursal} - ({value.existencias} pz.)
                    </p>
                    <p className="text-sm text-dark">{value.direccion}</p>
                  </div>
                </div>
              );
            })}
          <div className="flex flex-col justify-start items-start gap-2 w-full"></div>
        </DialogBody>
        <DialogFooter>
          <button
            onClick={handleOpenModal}
            className="bg-azul text-white py-3 rounded-md px-4 text-sm w-full border border-azul mr-auto"
          >
            Cerrar
          </button>
        </DialogFooter>
      </Dialog>
      <Dialog
        size="xs"
        open={openModalCredito}
        handler={handleOpenModalCredito}
      >
        <DialogHeader className="text-xl lg:text-2xl text-azul">
          ¿Quieres tu crédito?
        </DialogHeader>
        <DialogBody className="overflow-y-auto">
          <p>
            Puedes solicitar tu crédito en cualquiera de{" "}
            <a href="/Empresa/Sucursales" className="text-azul underline">
              nuestras sucursales
            </a>
            .
          </p>
          <br />
          <h4 className="text-azul font-medio">¿Tienes dudas?</h4>
          <p>
            Háblanos vía{" "}
            <a
              href="http://wa.me/528343110729"
              target="_blank"
              className="text-azul underline"
            >
              WhatsApp
            </a>{" "}
            y te ayudaremos con todas tus dudas.
          </p>
          <br />
          <small className="italic">
            Horario de atención 9:00 a 20:00 horas
          </small>
        </DialogBody>
        <DialogFooter className="-mt-6">
          <button
            onClick={handleOpenModalCredito}
            className="bg-azul text-white py-3 rounded-md px-4 text-sm w-full border border-azul mr-auto"
          >
            Cerrar
          </button>
        </DialogFooter>
      </Dialog>
      <section className="w-full px-[5vw] pt-4">
        <span className="text-xs inline">
          <a href="/" className="font-negro text-gris uppercase">
            Inicio /{" "}
          </a>
          <a
            href={`/Departamento/1/${
              data.articulo && data.articulo.length
                ? data.articulo[0].grupo
                : "1"
            }`}
            className="font-negro text-gris"
          >
            {data.articulo && data.articulo.length
              ? data.articulo[0].nom_grupo
              : ""}{" "}
            /{" "}
          </a>
          <span className="text-azul font-negro whitespace-pre-line box-border">
            {data.articulo && data.articulo.length
              ? data.articulo[0].descripcion_corta
              : ""}
          </span>
        </span>
      </section>
      <section className="w-full lg:w-11/12 mx-auto px-[5vw] pt-4 pb-8 lg:pb-10 flex flex-col lg:flex-row justify-center items-start gap-5 border-b border-b-gris/30">
        <div className="flex flex-col justify-start items-start lg:items-center w-full lg:w-5/12">
          {/* <div className="flex flex-row lg:justify-start lg:items-center gap-1 mr-auto">
                    <a href={`/Departamento/1/${data.articulo && data.articulo.length ? data.articulo[0].grupo : "1"}`} className='text-gris font-negro text-sm'>{data.articulo && data.articulo.length ? data.articulo[0].nom_grupo : ""}</a>
                    <FaChevronRight size={10} fill='#ADADAD' className='mt-1 lg:mt-0' />
                    <span className='text-azul font-negro text-sm whitespace-pre-line box-border'>{data.articulo && data.articulo.length ? data.articulo[0].descripcion_corta : ""}</span>
                </div> */}

          <div
            className="overflow-hidden hidden lg:block relative w-full rounded-xl border border-white mt-10 lg:mt-3 aspect-[16/12] cursor-zoom-in touch-none"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onMouseMove={handleMouseMove}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <img
              src={
                data.articulo && data.articulo.length
                  ? "https://s3.us-east-2.amazonaws.com/cdn.muebleriavillarreal/mvcweb/images/articulos_img/" +
                    data.articulo[0].departamento +
                    "/" +
                    data.articulo[0].articulo +
                    ".jpg?quality=auto&format=webp"
                  : ""
              }
              alt=""
              id="primaryImg"
              className={`primaryImg w-full aspect-square object-contain transition-transform duration-200 ${
                zoom ? "scale-150" : ""
              }`}
              style={{ transformOrigin: `${position.x}% ${position.y}%` }}
              onClick={(e) => handleImageClick(e.target.src)}
            />
            {isModalOpen && (
              <div
                className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
                onClick={handleCloseModal}
              >
                <div className="relative w-full">
                  <img
                    src={imageUrl}
                    alt=""
                    className="w-full h-[95lvh] object-contain mx-auto"
                  />
                </div>
              </div>
            )}
          </div>
          <div className="overflow-hidden lg:hidden relative w-full rounded-xl border border-white lg:mt-3">
            <img
              src={
                data.articulo && data.articulo.length
                  ? "https://s3.us-east-2.amazonaws.com/cdn.muebleriavillarreal/mvcweb/images/articulos_img/" +
                    data.articulo[0].departamento +
                    "/" +
                    data.articulo[0].articulo +
                    ".jpg?quality=auto&format=webp"
                  : ""
              }
              alt=""
              id="primaryImg"
              className={`primaryImg w-full md:w-1/2 aspect-square object-contain transition-transform duration-200 mx-auto ${
                zoom ? "scale-150" : ""
              }`}
              style={{ transformOrigin: `${position.x}% ${position.y}%` }}
              onClick={(e) => handleImageClick(e.target.src)}
            />
            {isModalOpen && (
              <div
                className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
                onClick={handleCloseModal}
              >
                <div className="relative w-full">
                  <img
                    src={imageUrl}
                    alt=""
                    className="w-full h-[95lvh] object-contain mx-auto"
                  />
                </div>
              </div>
            )}
          </div>
          <div className="flex flex-row flex-wrap w-full justify-center items-center gap-2 pt-4 lg:pt-0">
            {data.articulo && data.articulo.length && (
              <>
                <img
                  src={
                    "https://s3.us-east-2.amazonaws.com/cdn.muebleriavillarreal/mvcweb/images/articulos_img/" +
                    data.articulo[0].departamento +
                    "/" +
                    data.articulo[0].articulo +
                    ".jpg?quality=auto&format=webp"
                  }
                  className="size-20 lg:size-28 border border-white mt-3 cursor-pointer object-cover"
                  onClick={(e) => {
                    document.querySelectorAll(".primaryImg").forEach((img) => {
                      img.src = e.target.src;
                    });
                  }}
                  onError={(e) => (e.target.style.display = "none")}
                />
                <img
                  src={
                    "https://s3.us-east-2.amazonaws.com/cdn.muebleriavillarreal/mvcweb/images/articulos_img/" +
                    data.articulo[0].departamento +
                    "/" +
                    data.articulo[0].articulo +
                    "-2.jpg?quality=auto&format=webp"
                  }
                  className="size-20 lg:size-28 border border-white mt-3 cursor-pointer object-cover"
                  onClick={(e) => {
                    document.querySelectorAll(".primaryImg").forEach((img) => {
                      img.src = e.target.src;
                    });
                  }}
                  onError={(e) => (e.target.style.display = "none")}
                />
                <img
                  src={
                    "https://s3.us-east-2.amazonaws.com/cdn.muebleriavillarreal/mvcweb/images/articulos_img/" +
                    data.articulo[0].departamento +
                    "/" +
                    data.articulo[0].articulo +
                    "-3.jpg?quality=auto&format=webp"
                  }
                  className="size-20 lg:size-28 border border-white mt-3 cursor-pointer object-cover"
                  onClick={(e) => {
                    document.querySelectorAll(".primaryImg").forEach((img) => {
                      img.src = e.target.src;
                    });
                  }}
                  onError={(e) => (e.target.style.display = "none")}
                />
                <img
                  src={
                    "https://s3.us-east-2.amazonaws.com/cdn.muebleriavillarreal/mvcweb/images/articulos_img/" +
                    data.articulo[0].departamento +
                    "/" +
                    data.articulo[0].articulo +
                    "-4.jpg?quality=auto&format=webp"
                  }
                  className="size-20 lg:size-28 border border-white mt-3 cursor-pointer object-cover"
                  onClick={(e) => {
                    document.querySelectorAll(".primaryImg").forEach((img) => {
                      img.src = e.target.src;
                    });
                  }}
                  onError={(e) => (e.target.style.display = "none")}
                />
                <img
                  src={
                    "https://s3.us-east-2.amazonaws.com/cdn.muebleriavillarreal/mvcweb/images/articulos_img/" +
                    data.articulo[0].departamento +
                    "/" +
                    data.articulo[0].articulo +
                    "-5.jpg?quality=auto&format=webp"
                  }
                  className="size-20 lg:size-28 border border-white mt-3 cursor-pointer object-cover"
                  onClick={(e) => {
                    document.querySelectorAll(".primaryImg").forEach((img) => {
                      img.src = e.target.src;
                    });
                  }}
                  onError={(e) => (e.target.style.display = "none")}
                />
                <img
                  src={
                    "https://s3.us-east-2.amazonaws.com/cdn.muebleriavillarreal/mvcweb/images/articulos_img/" +
                    data.articulo[0].departamento +
                    "/" +
                    data.articulo[0].articulo +
                    "-6.jpg?quality=auto&format=webp"
                  }
                  className="size-20 lg:size-28 border border-white mt-3 cursor-pointer object-cover"
                  onClick={(e) => {
                    document.querySelectorAll(".primaryImg").forEach((img) => {
                      img.src = e.target.src;
                    });
                  }}
                  onError={(e) => (e.target.style.display = "none")}
                />
                <img
                  src={
                    "https://s3.us-east-2.amazonaws.com/cdn.muebleriavillarreal/mvcweb/images/articulos_img/" +
                    data.articulo[0].departamento +
                    "/" +
                    data.articulo[0].articulo +
                    "-7.jpg?quality=auto&format=webp"
                  }
                  className="size-20 lg:size-28 border border-white mt-3 cursor-pointer object-cover"
                  onClick={(e) => {
                    document.querySelectorAll(".primaryImg").forEach((img) => {
                      img.src = e.target.src;
                    });
                  }}
                  onError={(e) => (e.target.style.display = "none")}
                />
              </>
            )}
          </div>
        </div>
        <div className="flex flex-col justify-start items-start w-full lg:w-5/12 gap-2">
          <h1 className="text-xl lg:text-3xl font-negro">
            {data.articulo && data.articulo.length
              ? data.articulo[0].descripcion_corta
              : ""}
          </h1>
          <div className="w-full flex flex-col-reverse md:flex-row justify-start lg:items-center gap-2">
            <span className="text-sm text-gris font-medio pt-1">
              Cod:{" "}
              {data.articulo && data.articulo.length
                ? data.articulo[0].articulo
                : ""}
            </span>
            <Rating value={4} readonly />
          </div>
          <div className="flex flex-row justify-start items-end gap-2 pt-2 w-full">
            <span className="text-gris text-sm cross line-through font-medio">
              {data.articulo && data.articulo.length
                ? new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                  }).format(data.articulo[0].precio_lista)
                : ""}
            </span>
            <span className="text-rojo text-2xl lg:text-3xl cross font-negro">
              {data.articulo && data.articulo.length
                ? new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                  }).format(data.articulo[0].precio_contado)
                : ""}
            </span>
          </div>
          <span className="text-xs text-rojo pt-1 text-center lg:text-left w-full border-b border-b-gris/40 pb-6 font-medio">
            (Precio de riguroso contado exclusivo para muebleria-villarreal.com)
          </span>
          <div className="flex pt-4 pb-6 flex-row justify-center lg:justify-start items-center gap-4 border-b border-b-gris/40 w-full">
            <img src="/images/mvc.png" alt="" className="w-11" />
            <div className="flex flex-col justify-start items-start">
              <p className="text-base">
                Cómpralo con crédito Villarreal a{" "}
                <b className="text-azul font-bold">
                  {data.articulo &&
                    data.articulo.length &&
                    new Intl.NumberFormat("es-MX", {
                      style: "currency",
                      currency: "MXN",
                    }).format(data.articulo[0].precio_credito)}
                </b>
              </p>
              <p className="text-base text-azul font-negro">
                {data.articulo && data.articulo.length
                  ? data.articulo[0].plazo
                  : ""}{" "}
                Meses de{" "}
                {data.articulo && data.articulo.length
                  ? new Intl.NumberFormat("es-MX", {
                      style: "currency",
                      currency: "MXN",
                    }).format(data.articulo[0].importe_documentos)
                  : ""}
              </p>
              <p className="text-base">
                (
                {data.articulo && data.articulo.length
                  ? new Intl.NumberFormat("es-MX", {
                      style: "currency",
                      currency: "MXN",
                    }).format(data.articulo[0].importe_enganche)
                  : ""}{" "}
                <b>Enganche</b>)
              </p>
              <p
                onClick={handleOpenModalCredito}
                className="text-sm text-link underline cursor-pointer"
              >
                Más información
              </p>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row w-full justify-start items-center gap-2 mb-5 mt-4">
            <div className="flex flex-row justify-between lg:justify-start items-center border border-azul p-2 rounded-md w-full lg:w-fit">
              {data.articulo && data.articulo.length && (
                <>
                  <button
                    className="text-dark font-negro aspect-square w-7"
                    onClick={() =>
                      setQty((prevQty) => Math.max(1, prevQty - 1))
                    }
                  >
                    -
                  </button>
                  <input
                    type="text"
                    className=" border-0 appearance-none text-dark text-center w-24 outline-none"
                    min={1}
                    value={qty}
                    onKeyDown={(e) => {
                      if (e.key === "." || e.key === "e") {
                        e.preventDefault();
                      }
                    }}
                    onFocus={() => {
                      setQty("");
                    }}
                    onChange={(e) => {
                      let value = e.target.value;
                      // Allow the value to be an empty string
                      if (value === "") {
                        setQty("");
                      } else {
                        // Ensure the value is a number
                        if (isNaN(value)) {
                          value = 1;
                        } else {
                          value = parseInt(value, 10);
                        }
                        // Convert the value to a number
                        value = Number(value);
                        if(value > data.articulo[0].exist){
                          toast.error(
                            "Solamente contamos con " +
                              Number(data.articulo[0].exist) +
                              " articulos en el inventario.",
                            {
                              duration: 3000,
                            }
                          );
                        }
                        // Ensure the value is within the valid range
                        value = Math.max(
                          1,
                          Math.min(data.articulo[0].exist, value)
                        );
                        setQty(value);
                      }
                    }}
                    onBlur={(e) => {
                      // Re-validate the input on blur
                      let value = e.target.value;
                      if (isNaN(value)) {
                        value = 1;
                      } else if (value == 0) {
                        value = 1;
                      } else {
                        value = parseInt(value, 10);
                      }
                      value = Number(value);
                      value = Math.max(
                        1,
                        Math.min(data.articulo[0].exist, value)
                      );
                      setQty(value);
                    }}
                  />
                  <button
                    className="text-dark font-negro aspect-square w-7"
                    onClick={() =>
                      setQty((prevQty) => {
                        if (prevQty >= data.articulo[0].exist) {
                          toast.error(
                            "Solamente contamos con " +
                            Number(data.articulo[0].exist) +
                              " articulos en el inventario.",
                            {
                              duration: 3000,
                            }
                          );
                        }
                        return Math.min(data.articulo[0].exist, prevQty + 1);
                      })
                    }
                  >
                    +
                  </button>
                </>
              )}
            </div>
            <button
              onClick={agregarCarrito}
              className="bg-azul text-white py-3 rounded-md px-4 text-sm w-full lg:w-fit min-w-72 border border-azul"
            >
              Agregar al Carrito
            </button>
          </div>
          <div className="p-5 w-full bg-gris/20 rounded-md">
            <p className="flex flex-row justify-start items-center gap-1 text-azul font-negro text-xs mb-1">
              <MdWatchLater size={16} className="fill-azul" />
              Recoge en tienda
            </p>
            <button
              onClick={() => setOpenModal(true)}
              className="text-xs underline"
            >
              Ver disponibilidad en tiendas
            </button>
            <p className="text-xs pt-3">
              Selecciona una tienda para conocer si puedes recoger este producto
              el día de hoy
            </p>
          </div>
        </div>
      </section>
      <section className="w-full lg:w-10/12 mx-auto px-[5vw] py-8 lg:py-10">
        <Tabs value="Caracteristicas">
          <TabsHeader
            className="bg-transparent flex flex-col lg:flex-row gap-2"
            indicatorProps={{
              className:
                "bg-transparent border-2 border-azul shadow-none rounded-full",
            }}
          >
            <Tab
              value="Caracteristicas"
              className="rounded-full py-3 lg:py-2 w-full lg:w-fit px-8 font-medio text-sm border border-gris/20"
            >
              <span className="font-medio">Características</span>
            </Tab>
            {dataTable.map(({ label, value }) => (
              <Tab
                key={value}
                value={value}
                className="rounded-full py-3 lg:py-2 w-full lg:w-fit px-8 font-medio text-sm border border-gris/20"
              >
                <span className="font-medio">{label}</span>
              </Tab>
            ))}
          </TabsHeader>
          <TabsBody>
            <TabPanel
              value="Caracteristicas"
              className="text-sm text-center lg:text-left"
            >
              <p className="text-xl lg:text-3xl text-azul pb-1 lg:pb-5 uppercase">
                Características
              </p>
              <table className="w-full mt-5 tableCaracteristicas">
                <tbody>
                  {data.caracteristicas &&
                    data.caracteristicas.length > 0 &&
                    data.caracteristicas.map((value, index) => (
                      <tr key={index}>
                        <td className="border border-dashed border-gray-500 w-1/2 p-2 text-xs lg:text-sm font-medio">
                          {value.caracteristica}
                        </td>
                        <td className="border border-dashed border-gray-500 w-1/2 p-2 text-xs lg:text-sm">
                          {value.valor}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </TabPanel>
            {dataTable.map(({ value, desc }) => {
              if (value === "angular") {
                return (
                  <TabPanel
                    key={value}
                    value={value}
                    className="text-sm text-center lg:text-left"
                  >
                    {CONTENTS.map(
                      ({ name, feedback, img, date, rating }, index) => (
                        <CardReview
                          key={index}
                          name={name}
                          feedback={feedback}
                          img={img}
                          date={date}
                          rating={rating}
                        />
                      )
                    )}
                  </TabPanel>
                );
              } else {
                return (
                  <TabPanel
                    key={value}
                    value={value}
                    className="text-sm text-center lg:text-left"
                  >
                    {desc.map((item, index) => {
                      if (item.includes("-")) {
                        return (
                          <p key={index} className="text-dark pb-3 text-base">
                            {item}
                          </p>
                        );
                      } else {
                        return (
                          <h5
                            key={index}
                            className="text-xl lg:text-3xl text-azul pb-1 lg:pb-5 uppercase"
                          >
                            {item}
                          </h5>
                        );
                      }
                    })}
                  </TabPanel>
                );
              }
            })}
          </TabsBody>
        </Tabs>
      </section>
      <section className="w-full px-[5vw] py-10 lg:py-20">
        <h2 className="font-negro text-xl lg:text-3xl text-left uppercase">
          Productos Relacionados
        </h2>
        <div className="hidden lg:flex flex-row justify-between items-start gap-4">
          {data.sugerencias &&
            data.sugerencias.length &&
            data.sugerencias.map((producto, index) => (
              <a
                key={index}
                href={"/Articulo/" + producto.articulo + "/" + producto.grupo}
                className="p-5 w-full flex flex-col justify-start items-start transition-all hover:scale-105 hover:shadow-xl"
              >
                <img
                  src={
                    "https://s3.us-east-2.amazonaws.com/cdn.muebleriavillarreal/mvcweb/images/articulos_img/" +
                    producto.departamento +
                    "/" +
                    producto.articulo +
                    ".jpg?quality=auto&format=webp"
                  }
                  alt=""
                  className="w-full aspect-square object-cover object-center rounded-lg"
                />
                <span className="text-sm text-darkGris">
                  {producto.nom_grupo}
                </span>
                <h5 className="font-negro leading-tight pt-2 text-lg">
                  {producto.descripcion_corta}
                </h5>
                <div className="flex justify-start items-end gap-2 pt-3">
                  <span className="text-gris text-sm cross line-through font-medio">
                    {new Intl.NumberFormat("es-MX", {
                      style: "currency",
                      currency: "MXN",
                    }).format(producto.precio_lista)}
                  </span>
                  <span className="text-rojo text-xl cross font-medio">
                    {new Intl.NumberFormat("es-MX", {
                      style: "currency",
                      currency: "MXN",
                    }).format(producto.precio_contado)}
                  </span>
                </div>
              </a>
            ))}
        </div>
        <div className="w-full py-2 block lg:hidden">
          <Splide
            hasTrack={false}
            id="splidePopularesHome"
            options={{
              perPage: 2,
              trimSpace: false,
              arrows: false,
              gap: "1rem",
              type: "loop",
              breakpoints: {
                10: {
                  type: "loop",
                },
                640: {
                  perPage: 1,
                  type: "loop",
                },
              },
            }}
          >
            <SplideTrack>
              {data.sugerencias &&
                data.sugerencias.length &&
                data.sugerencias.map((producto, index) => (
                  <SplideSlide key={index}>
                    <a
                      href={
                        "/Articulo/" + producto.articulo + "/" + producto.grupo
                      }
                      className="p-5 w-full flex flex-col justify-start items-start"
                    >
                      <img
                        src={
                          "https://s3.us-east-2.amazonaws.com/cdn.muebleriavillarreal/mvcweb/images/articulos_img/" +
                          producto.departamento +
                          "/" +
                          producto.articulo +
                          ".jpg?quality=auto&format=webp"
                        }
                        alt=""
                        className="w-full aspect-square object-cover object-center rounded-lg"
                      />
                      <span className="text-sm text-darkGris">
                        {producto.nom_grupo}
                      </span>
                      <h5 className="font-negro leading-tight pt-2 text-lg">
                        {producto.descripcion_corta}
                      </h5>
                      <div className="flex flex-col justify-start items-start gap-0 pt-3 w-full">
                        <span className="text-gris text-sm cross line-through font-medio">
                          {new Intl.NumberFormat("es-MX", {
                            style: "currency",
                            currency: "MXN",
                          }).format(producto.precio_lista)}
                        </span>
                        <span className="text-rojo lg:text-xl cross font-medio">
                          {new Intl.NumberFormat("es-MX", {
                            style: "currency",
                            currency: "MXN",
                          }).format(producto.precio_contado)}
                        </span>
                      </div>
                    </a>
                  </SplideSlide>
                ))}
            </SplideTrack>
          </Splide>
        </div>
      </section>
    </Layout>
  );
};

export default Producto;
