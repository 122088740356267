import React, { useEffect, useState } from 'react'
import Layout from '../../layout/Layout'
import { useParams } from 'react-router-dom';
import { TbLoader3 } from "react-icons/tb";
import { Chip } from "@material-tailwind/react";

import { Toaster, toast } from 'sonner';

const MesaRegalosConsult = () => {

    const {id} = useParams();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({
        evento: [],
        results: []
    });

    useEffect(() => {
        fetch('https://muebleria-villarreal.com/apimv/mesaregalosNumController.php?evento=' + id)
            .then(response => response.json())
            .then(data => {
                setData(data)
                if (data.evento.length === 0) {
                    toast.error('No se encontró la mesa de regalos', {
                        onDismiss: () => {
                            window.location.href = '/mesaregalos'
                        },
                        onAutoClose: () => {
                            window.location.href = '/mesaregalos'
                        },
                        duration: 3000
                    });
                } else {
                    setTimeout(() => {
                        setLoading(false);
                    }, 500);
                }
                console.log('Response data:', data);
            })
    }, [])

    const notify = () => toast.info('Articulo Agregado', {
        duration: 3000
    });

    const agregarCarrito = (dataPopup) => {
        notify();
        const cart = JSON.parse(localStorage.getItem('cartMesaRegalos' + id)) || [];
    
        const productIndex = cart.findIndex(item => item.articulo.articulo === dataPopup.articulo);
    
        if (productIndex !== -1) {
            cart[productIndex].qty = 1;
        } else {
            cart.push({
                articulo: dataPopup,
                qty: 1
            });
        }
    
        localStorage.setItem('cartMesaRegalos' + id, JSON.stringify(cart));
        window.dispatchEvent(new Event('cartUpdated'));
    }

  return (
    <Layout>
        {
            loading &&
                <div className="w-full h-lvh flex justify-center items-center fixed z-50 bg-white/95 top-0 left-0">
                    <TbLoader3 size={100} className='stroke-azul animate-spin' />
                </div>
        }
        {
            data.evento && data.evento.length > 0 &&
            <section className='container mx-auto px-4 py-10'>
                <h1 className='text-center text-azul font-medio mx-auto text-xl lg:text-3xl'>{data.evento[0].nombre}</h1>
                <h2 className='text-center mx-auto pt-2'>Fecha del evento: {data.evento[0].fecha_evento.split(' ')[0]}</h2>
                <div className="flex flex-row flex-wrap justify-between items-start gap-0 pt-5">
                {
                    Array.isArray(Object.values(data.results)) && Object.values(data.results).map((producto, index) => (
                        <div key={index} className='w-full lg:w-3/12 p-5 transition-all hover:scale-105 hover:shadow-xl relative'>
                            <a href={"/Articulo/" + producto.articulo.articulo + "/" + producto.articulo.grupo} className='w-full flex flex-col justify-start items-start'>
                                <img src={"https://s3.us-east-2.amazonaws.com/cdn.muebleriavillarreal/mvcweb/images/articulos_img/" + producto.articulo.departamento + "/" + producto.articulo.articulo + ".jpg?quality=auto&format=webp"} alt="" className='w-full aspect-square object-cover object-center rounded-lg' />
                                <span className='text-sm text-darkGris'>{producto.articulo.nom_grupo}</span>
                                <h5 className='font-negro leading-tight pt-2 text-lg'>{producto.articulo.descripcion_corta}</h5>
                                <div className="flex justify-start items-end gap-2 py-3">
                                    <span className='text-gris text-sm cross line-through font-medio'>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(producto.articulo.precio_lista)}</span>
                                    <span className='text-rojo text-xl cross font-medio'>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(producto.articulo.precio_contado)}</span>
                                </div>
                                <Chip size='sm' color={producto.restante == 0 ? 'green' : 'blue'} className='chipMesa absolute z-20 top-5 right-5' value={producto.vendido + " de " + producto.restante} />
                            </a>
                            <button className='bg-azul py-2 px-3 text-white text-sm rounded flex justify-center items-center gap-2 w-full' onClick={() => agregarCarrito(producto.articulo)}>Agregar a Carrito</button>
                        </div>
                    ))
                }
                </div>
            </section>
        }
    </Layout>
  )
}

export default MesaRegalosConsult