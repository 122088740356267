import React, {useState, useEffect} from 'react'
import Layout from '../../layout/Layout'
import { TbLoader3 } from "react-icons/tb";
import { FaEye } from "react-icons/fa";

import { Button, Input } from '@material-tailwind/react'
import { Alert } from "@material-tailwind/react";

import { Toaster, toast } from 'sonner';
import { useParams } from 'react-router-dom';

const ConfirmResetPassword = () => {
  const [loading, setLoading] = useState(true)
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(true);
  const [password, setPassword] = useState('')
  const [showPw, setShowPw] = useState(false)
  const [confirmPw, setConfirmPw] = useState('')
  const [showPw2, setShowPw2] = useState(false)

  const { key } = useParams();

  const isEmailValid = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
  };

  useEffect(() => {
    const emailParam = new URLSearchParams(window.location.search).get('email');
    if(emailParam && isEmailValid(emailParam) && key) {
        fetch('https://muebleria-villarreal.com/apimv/checkPasswordResetTokenController.php?email=' + emailParam + '&token=' + key)
            .then(response => response.json())
            .then(data => {
                if(data.result == "success") {
                    setEmail(emailParam)
                    setLoading(false)
                } else {
                    // window.location.href = '/login'
                    console.log('error: ' + emailParam)
                }
            })
    } else {
        window.location.href = '/login'
    }
  }, [key])

  const handleSubmit = () => {
    setLoading(true)
    fetch('https://muebleria-villarreal.com/apimv/updateContraseñaController.php?email=' + email + '&password=' + password + '&token=' + key)
        .then(response => response.json())
        .then(data => {
            if(data.result == "success") {
              setTimeout(() => {
                window.location.href = '/login'
              }, 2000)
            } else if(data.result == "error_sql_check") {
              setTimeout(() => {
                setLoading(false)
                toast.error('No encontramos tu solicitud. Favor de revisar tu correo nuevamente.', {
                    duration: 5000
                });
              }, 2000)
            } else if(data.result == "error_sql") {
              setTimeout(() => {
                setLoading(false)
                toast.error('No pudimos actualizar tu contraseña. Favor de intentar nuevamente', {
                    duration: 5000
                });
              }, 2000)
            } else {
              setTimeout(() => {
                setLoading(false)
                toast.error('Error al enviar la solicitud. Favor de intentar nuevamente.', {
                    duration: 5000
                });
              }, 2000)
            }
        })
        .catch(error => {
            console.error('Error:', error);
        });
  }

  return (
    <Layout>
        <section className='w-full pt-20 pb-28 min-h-[60vh] bg-[url("/images/Resetpass.jpg")] bg-cover bg-center flex justify-center items-center'>
          {/* <Toaster richColors closeButton position="top-right"  /> */}
            {
                loading &&
                <div className='w-full h-lvh bg-black bg-opacity-70 flex justify-center items-center fixed top-0 left-0 z-40'>
                    <TbLoader3 className='stroke-white animate-spin h-20 w-20' />
                </div>
            }
            <div className='container mx-auto flex flex-col lg:flex-row justify-between items-start gap-5 px-4'>
                <div className='w-full lg:w-4/12 flex flex-col justify-start items-start gap-2'>
                </div>
                <div className='w-full lg:w-4/12 bg-white p-5 rounded-lg flex flex-col justify-center items-center gap-2' style={{ boxShadow: "0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0)" }}>
                    <h2 className='text-xl text-azul font-negro text-center'>Actualiza tu contraseña</h2>
                    <Input size='lg' label='Correo *' type='email' value={email} disabled />
                    <Input icon={<FaEye className={`cursor-pointer ${showPw ? "fill-azul" : "fill-darkGris/40"}`} size={20} onClick={() => setShowPw(!showPw)} />} error={password.length <= 6 && password !== ""} size='lg' label='Contraseña *' type={showPw ? "text" : "password"} value={password} onChange={(e) => setPassword(e.target.value)} />
                    <Input icon={<FaEye className={`cursor-pointer ${showPw2 ? "fill-azul" : "fill-darkGris/40"}`} size={20} onClick={() => setShowPw2(!showPw2)} />} error={confirmPw.length <= 6 && confirmPw !== "" || confirmPw !== password} size='lg' label='Confirmar Contraseña *' type={showPw2 ? "text" : "password"} value={confirmPw} onChange={(e) => setConfirmPw(e.target.value)} />
                    <Button onClick={handleSubmit} className={`bg-azul text-sm px-4 text-white rounded-md mx-auto w-full mt-3 transition-all opacity-100 hover:bg-link`} disabled={confirmPw.length <= 6 && confirmPw !== "" || confirmPw !== password}>Confirmar</Button>
                    <p className='pt-3 text-sm'>Recuerda apuntarla en un lugar donde puedas recordarla, y si la pierdes, siempre puedes renovar tu contraseña</p>
                </div>
            </div>
        </section>
    </Layout>
  )
}

export default ConfirmResetPassword